import * as React from "react";
import PropTypes from "prop-types";
import { FormControl } from "../atoms/FormControl";
import { FormLabel } from "../atoms/FormLabel";
import { TextField } from "../atoms/TextField";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { Divider, Grid, InputAdornment, Paper, Stack, Typography } from "@mui/material";
import { SelectForm } from "../atoms/SelectForm";
import { AddButton } from "../atoms/AddButton";
import { DeleteButton } from "../atoms/DeleteButton";
import * as validationRules from "../../utils/ValidationRules";
import { HiddenInput } from "../atoms/HiddenInput";
import { DatePicker } from "../atoms/DatePicker";
import { Box } from "@mui/system";
import { isMobile } from "react-device-detect";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { LoadingProgress } from "../atoms/LoadingProgress";
import { useFocusInput, useInitBusinessBalance, useInitCompanyPayeeBroker, useInitPrivateRoad, useInitRoadsideLandPricesRatio, useInitSpecialFactor, useInitTradeTargetLotNumber, useCompanyPayeeBrokerName } from "../../utils/CustomHook";
import { onBlurCommonAction, removeChild, saveChildForPropertyEdit, saveProperty } from "../../utils/PropertyEdit";

export const BusinessBalanceForms = React.memo(function businessBalanceForms({
  choices,
  businessbalance,
  property,
  companypayeebroker,
  initialized,
  setOriginPropertyData,
  toggleViewport,
  formPaperVariant,
  formPaperElevation,
  ...props
}) {
  const [isLoading, setIsLoading] = React.useState(false)
  const loadingComplete = React.useCallback(() => {
    setIsLoading(false)
  }, [])

  const [isOpenBusinessBalanceForm, setIsOpenBusinessBalanceForm] = React.useState(false)
  const [isOpenBusinessBalanceTradeTargetLotNumberForm, setIsOpenBusinessBalanceTradeTargetLotNumberForm] = React.useState(false)
  const [isOpenBusinessBalancePrivateRoadForm, setIsOpenBusinessBalancePrivateRoadForm] = React.useState(false)
  const [isOpenBusinessBalanceRoadsideLandPricesRatioForm, setIsOpenBusinessBalanceRoadsideLandPricesRatioForm] = React.useState(false)
  const [isOpenBusinessBalanceOtherForm, setIsOpenBusinessBalanceOtherForm] = React.useState(false)

  const [propertyId, setPropertyId] = React.useState()

  const [isFocused, setFocusInputName] = useFocusInput()
  const handleFocus = React.useCallback((name) => {
    setFocusInputName(name)
  }, [])

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    methods,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange",
  });

  useInitBusinessBalance(businessbalance, setValue, isFocused, errors)
  useInitCompanyPayeeBroker(companypayeebroker, setValue, isFocused, errors)
  const [tradeTargetLotNumberFields, tradeTargetLotNumberAppend, tradeTargetLotNumberRemove] = useInitTradeTargetLotNumber(businessbalance.businessbalancetradetargetlotnumber_set, setValue, isFocused, control, trigger)
  const [privateRoadFields, privateRoadAppend, privateRoadRemove] = useInitPrivateRoad(businessbalance.businessbalanceprivateroad_set, setValue, isFocused, control, trigger)

  const roadsideLandPricesRatioFields = useInitRoadsideLandPricesRatio(businessbalance.roadside_land_prices_ratio_target_year, businessbalance.businessbalanceroadsidelandpricesratio_set, setValue, isFocused, control, trigger)

  React.useEffect(() => {
    if (!Object.keys(property).length && !Object.keys(businessbalance).length) {
      return;
    }

    setPropertyId(property.id)
    if (!isFocused("name")) {
      setValue("name", property.name, { shouldValidate: true });
    }

    initialized();
  }, [businessbalance, property]);

  const createTradeTargetLotNumberForm = React.useCallback(() => {
    tradeTargetLotNumberAppend();
  }, []);

  const createPrivateRoadForm = React.useCallback(() => {
    privateRoadAppend();
  }, []);

  const saveCommonParams = React.useMemo(() => [
    errors, propertyId, clearErrors, setOriginPropertyData, setError
  ], [errors, propertyId, clearErrors, setOriginPropertyData, setError])

  const removeChildCommonParams = React.useMemo(() => [
    propertyId, getValues, setOriginPropertyData,
  ], [propertyId, getValues, setOriginPropertyData])

  const onBlurForProperty = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    saveProperty(e, ...saveCommonParams)
  }, [propertyId]);

  const onBlurForBusinessBalance = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "businessbalance",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId, errors]);

  const onBlurForTradeTargetLotNumber = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "business_balance", "businessbalance", "businessbalancetradetargetlotnumber_set"
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId, errors]);

  const onBlurForSpecialFactor = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "business_balance", "businessbalance", "businessbalancespecialfactor_set"
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId, errors]);

  const handleRemoveForTradeTargetLotNumber = React.useCallback((index) => {
    removeChild(...removeChildCommonParams, tradeTargetLotNumberRemove, index, "businessbalance", "businessbalancetradetargetlotnumber_set")
  }, [propertyId]);

  const onBlurForPrivateRoad = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "business_balance", "businessbalance", "businessbalanceprivateroad_set"
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId, errors]);

  const handleRemoveForPrivateRoad = React.useCallback((index) => {
    removeChild(...removeChildCommonParams, privateRoadRemove, index, "businessbalance", "businessbalanceprivateroad_set")
  }, [propertyId]);

  const onBlurForRoadsideLandPricesRatio = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "business_balance", "businessbalance", "businessbalanceroadsidelandpricesratio_set"
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId, errors]);

  const onBlurForCompanyPayeeBroker = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "companypayeebroker",
    ]
    saveChildForPropertyEdit(e, ...saveCommonParams, ...saveChildKeys)
  }, [propertyId, errors]);

  const rules = React.useMemo(() => ({
    name: {
      ...validationRules.required(),
      ...validationRules.maxLength(200),
      onBlur: onBlurForProperty,
    },
    businessbalance: {
      buildings_count: {
        onBlur: onBlurForBusinessBalance,
      },
      sales_method: {
        onBlur: onBlurForBusinessBalance,
      },
      information_acquisition_person: {
        onBlur: onBlurForBusinessBalance,
      },
      business_representative: {
        onBlur: onBlurForBusinessBalance,
      },
      sales_channel: {
        onBlur: onBlurForBusinessBalance,
      },
      sales_channel_target: {
        onBlur: onBlurForBusinessBalance,
      },
      hazard: {
        onBlur: onBlurForBusinessBalance,
      },
      hazard_text: {
        onBlur: onBlurForBusinessBalance,
      },
      flooding_history: {
        onBlur: onBlurForBusinessBalance,
      },
      flooding_history_text: {
        onBlur: onBlurForBusinessBalance,
      },
      leasehold: {
        onBlur: onBlurForBusinessBalance,
      },
      information_target_type: {
        onBlur: onBlurForBusinessBalance,
      },
      specification: {
        ...validationRules.maxLength(100),
        onBlur: onBlurForBusinessBalance,
      },
      specification_area: {
        onBlur: onBlurForBusinessBalance,
      },
      property_city: {
        onBlur: onBlurForBusinessBalance,
      },
      property_building_name: {
        ...validationRules.maxLength(100),
        onBlur: onBlurForBusinessBalance,
      },
      property_block: {
        ...validationRules.maxLength(100),
        onBlur: onBlurForBusinessBalance,
      },
      cost_price: {
        onBlur: onBlurForBusinessBalance,
      },
      businessbalanceprivateroad_set: {
        category: {
          onBlur: onBlurForPrivateRoad,
        },
        block: {
          onBlur: onBlurForPrivateRoad,
        },
        area: {
          onBlur: onBlurForPrivateRoad,
        },
        equity: {
          onBlur: onBlurForPrivateRoad,
        },
      },
      businessbalancetradetargetlotnumber_set: {
        category: {
          onBlur: onBlurForTradeTargetLotNumber,
        },
        block: {
          ...validationRules.maxLength(100),
          onBlur: onBlurForTradeTargetLotNumber,
        },
        area: {
          onBlur: onBlurForTradeTargetLotNumber,
        },
      },
      businessbalanceroadsidelandpricesratio_set: {
        price: {
          onBlur: onBlurForRoadsideLandPricesRatio,
        },
      },
      businessbalancespecialfactor_set: {
        special_factor: {
          onBlur: onBlurForSpecialFactor,
        },
      },
    },
    companypayeebroker: {
      name: {
        onBlur: onBlurForCompanyPayeeBroker,
      },
    },
  }), [onBlurForProperty, onBlurForBusinessBalance, onBlurForPrivateRoad, onBlurForTradeTargetLotNumber, onBlurForRoadsideLandPricesRatio, onBlurForSpecialFactor, onBlurForCompanyPayeeBroker]);

  const specialFactorFields = useInitSpecialFactor(businessbalance.businessbalancespecialfactor_set, setValue, isFocused, control, rules.businessbalance.businessbalancespecialfactor_set, trigger)

  const propertyEditFormDialogCloseAction = React.useCallback(() => {
    toggleViewport()
  }, [])
  const formAreaClickedAction = React.useCallback((additionalProcess) => {
    if (!isMobile) {
      return
    }
    if (additionalProcess) {
      additionalProcess()
    }
    setIsLoading(true)
    toggleViewport()
  }, [])

  const commonInputProps = React.useMemo(() => ({
    control: control,
    onFocus: handleFocus,
  }), [control, handleFocus])

  return (
    <PropertyEditForms title="事業収支">
      <LoadingProgress open={isLoading} useCircular={false} />
      <FormProvider {...methods}>
        <PropertyEditFormDialog
          isOpen={isOpenBusinessBalanceForm}
          handleClose={() => {
            setIsOpenBusinessBalanceForm(false)
            propertyEditFormDialogCloseAction()
          }}
          title="事業収支"
          openedAction={loadingComplete}
          content={
            <BusinessBalanceForm
              commonInputProps={commonInputProps}
              rules={rules}
              disabled={props.disabled}
              choices={choices}
              setValue={setValue}
              getValues={getValues}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenBusinessBalanceTradeTargetLotNumberForm}
          handleClose={() => {
            setIsOpenBusinessBalanceTradeTargetLotNumberForm(false)
            propertyEditFormDialogCloseAction()
          }}
          title={
            <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
              売買対象地番
              <AddButton
                onClick={createTradeTargetLotNumberForm}
                disabled={props.disabled || businessbalance.MAX_ROW_BUSINESS_BALANCE_TRADE_TARGET_LOT_NUMBER <= tradeTargetLotNumberFields.length}
              />
            </Stack>
          }
          openedAction={loadingComplete}
          content={
            <>
              <BusinessBalanceTradeTargetLotNumberForm
                commonInputProps={commonInputProps}
                rules={rules}
                disabled={props.disabled}
                choices={choices}
                createTradeTargetLotNumberForm={createTradeTargetLotNumberForm}
                handleRemoveForTradeTargetLotNumber={handleRemoveForTradeTargetLotNumber}
                errors={errors}
                MAX_ROW_BUSINESS_BALANCE_TRADE_TARGET_LOT_NUMBER={businessbalance.MAX_ROW_BUSINESS_BALANCE_TRADE_TARGET_LOT_NUMBER ?? 0}
                tradeTargetLotNumberFields={tradeTargetLotNumberFields}
                isModal={true}
                setValue={setValue}
              />
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", mt: 1 }}
                onClick={!(props.disabled || businessbalance.MAX_ROW_BUSINESS_BALANCE_TRADE_TARGET_LOT_NUMBER <= tradeTargetLotNumberFields.length) ? createTradeTargetLotNumberForm : undefined}
              >
                <Typography sx={{ color: (props.disabled || businessbalance.MAX_ROW_BUSINESS_BALANCE_TRADE_TARGET_LOT_NUMBER <= tradeTargetLotNumberFields.length) ? "gray.main" : "darkGreen.main" }}>
                  追加
                </Typography>
                <AddButton disabled={props.disabled || businessbalance.MAX_ROW_BUSINESS_BALANCE_TRADE_TARGET_LOT_NUMBER <= tradeTargetLotNumberFields.length} sx={{ ml: 1 }} />
              </Stack>
            </>
          }
          actions={<BusinessBalanceTradeTargetLotNumberTotalForm control={control} />}
        />
        <PropertyEditFormDialog
          isOpen={isOpenBusinessBalancePrivateRoadForm}
          handleClose={() => {
            setIsOpenBusinessBalancePrivateRoadForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title={
            <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
              私道
              <AddButton
                onClick={createPrivateRoadForm}
                disabled={props.disabled || businessbalance.MAX_ROW_BUSINESS_BALANCE_PRIVATE_ROAD <= privateRoadFields.length}
              />
            </Stack>
          }
          content={
            <>
              <BusinessBalancePrivateRoadForm
                commonInputProps={commonInputProps}
                rules={rules}
                disabled={props.disabled}
                choices={choices}
                createPrivateRoadForm={createPrivateRoadForm}
                handleRemoveForPrivateRoad={handleRemoveForPrivateRoad}
                errors={errors}
                MAX_ROW_BUSINESS_BALANCE_PRIVATE_ROAD={businessbalance.MAX_ROW_BUSINESS_BALANCE_PRIVATE_ROAD ?? 0}
                privateRoadFields={privateRoadFields}
                isModal={true}
                setValue={setValue}
              />
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: "100%", mt: 1 }}
                onClick={!(props.disabled || businessbalance.MAX_ROW_BUSINESS_BALANCE_PRIVATE_ROAD <= privateRoadFields.length) ? createPrivateRoadForm : undefined}
              >
                <Typography sx={{ color: (props.disabled || businessbalance.MAX_ROW_BUSINESS_BALANCE_PRIVATE_ROAD <= privateRoadFields.length) ? "gray.main" : "darkGreen.main" }}>
                  追加
                </Typography>
                <AddButton disabled={props.disabled || businessbalance.MAX_ROW_BUSINESS_BALANCE_PRIVATE_ROAD <= privateRoadFields.length} sx={{ ml: 1 }} />
              </Stack>
            </>
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenBusinessBalanceRoadsideLandPricesRatioForm}
          handleClose={() => {
            setIsOpenBusinessBalanceRoadsideLandPricesRatioForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="路線価比"
          content={
            <BusinessBalanceRoadsideLandPricesRatioForm
              commonInputProps={commonInputProps}
              rules={rules}
              disabled={props.disabled}
              errors={errors}
              roadsideLandPricesRatioFields={roadsideLandPricesRatioFields}
              isModal={true}
            />
          }
        />
        <PropertyEditFormDialog
          isOpen={isOpenBusinessBalanceOtherForm}
          handleClose={() => {
            setIsOpenBusinessBalanceOtherForm(false)
            propertyEditFormDialogCloseAction()
          }}
          openedAction={loadingComplete}
          title="その他"
          content={
            <BusinessBalanceOtherForm
              commonInputProps={commonInputProps}
              businessbalance={businessbalance}
              rules={rules}
              disabled={props.disabled}
              choices={choices}
              errors={errors}
              specialFactorFields={specialFactorFields}
              setValue={setValue}
            />
          }
        />
        <form>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={9}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenBusinessBalanceForm(true))}
                  >
                    <BusinessBalanceForm
                      commonInputProps={commonInputProps}
                      rules={rules}
                      disabled={props.disabled}
                      choices={choices}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenBusinessBalanceTradeTargetLotNumberForm(true))}
                  >
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row">
                        <FormLabel>
                          <Typography variant="subtitle1">
                            売買対象地番
                          </Typography>
                        </FormLabel>
                        <AddButton
                          onClick={createTradeTargetLotNumberForm}
                          disabled={props.disabled || businessbalance.MAX_ROW_BUSINESS_BALANCE_TRADE_TARGET_LOT_NUMBER <= tradeTargetLotNumberFields.length}
                        />
                      </Stack>
                      <BusinessBalanceTradeTargetLotNumberForm
                        commonInputProps={commonInputProps}
                        rules={rules}
                        disabled={props.disabled}
                        choices={choices}
                        createTradeTargetLotNumberForm={createTradeTargetLotNumberForm}
                        handleRemoveForTradeTargetLotNumber={handleRemoveForTradeTargetLotNumber}
                        errors={errors}
                        MAX_ROW_BUSINESS_BALANCE_TRADE_TARGET_LOT_NUMBER={businessbalance.MAX_ROW_BUSINESS_BALANCE_TRADE_TARGET_LOT_NUMBER ?? 0}
                        tradeTargetLotNumberFields={tradeTargetLotNumberFields}
                        isModal={false}
                        setValue={setValue}
                      />
                      <BusinessBalanceTradeTargetLotNumberTotalForm control={control} />
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={8} container direction="row" spacing={1}>
                  <Grid item xs={12}>
                    <Paper
                      elevation={formPaperElevation}
                      variant={formPaperVariant}
                      onClick={() => formAreaClickedAction(() => setIsOpenBusinessBalancePrivateRoadForm(true))}
                    >
                      <Stack direction="column" spacing={1}>
                        <Stack direction="row">
                          <FormLabel>
                            <Typography variant="subtitle1">私道</Typography>
                          </FormLabel>
                          <AddButton
                            onClick={createPrivateRoadForm}
                            disabled={props.disabled || businessbalance.MAX_ROW_BUSINESS_BALANCE_PRIVATE_ROAD <= privateRoadFields.length}
                          />
                        </Stack>
                        <BusinessBalancePrivateRoadForm
                          commonInputProps={commonInputProps}
                          rules={rules}
                          disabled={props.disabled}
                          choices={choices}
                          createPrivateRoadForm={createPrivateRoadForm}
                          handleRemoveForPrivateRoad={handleRemoveForPrivateRoad}
                          errors={errors}
                          MAX_ROW_BUSINESS_BALANCE_PRIVATE_ROAD={businessbalance.MAX_ROW_BUSINESS_BALANCE_PRIVATE_ROAD ?? 0}
                          privateRoadFields={privateRoadFields}
                          isModal={false}
                          setValue={setValue}
                        />
                      </Stack>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper
                      elevation={formPaperElevation}
                      variant={formPaperVariant}
                      onClick={() => formAreaClickedAction(() => setIsOpenBusinessBalanceRoadsideLandPricesRatioForm(true))}
                    >
                      <Typography variant="subtitle1">路線価比</Typography>
                      <BusinessBalanceRoadsideLandPricesRatioForm
                        commonInputProps={commonInputProps}
                        rules={rules}
                        disabled={props.disabled}
                        errors={errors}
                        roadsideLandPricesRatioFields={roadsideLandPricesRatioFields}
                        isModal={false}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Paper
                elevation={formPaperElevation}
                variant={formPaperVariant}
                onClick={() => formAreaClickedAction(() => setIsOpenBusinessBalanceOtherForm(true))}
              >
                <BusinessBalanceOtherForm
                  commonInputProps={commonInputProps}
                  businessbalance={businessbalance}
                  rules={rules}
                  disabled={props.disabled}
                  choices={choices}
                  errors={errors}
                  specialFactorFields={specialFactorFields}
                  setValue={setValue}
                />
              </Paper>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </PropertyEditForms>
  );
});

BusinessBalanceForms.propTypes = {
  property: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  businessbalance: PropTypes.object.isRequired,
  companypayeebroker: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

BusinessBalanceForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};


const BusinessBalanceForm = React.memo(function BusinessBalanceForm(props) {
  const { commonInputProps, rules, choices, setValue, getValues } = props

  const [getCompanyPayeeBrokerNameChoices, companyPayeeBrokerNameChoices, companyPayeeBrokerNameIsLoading] = useCompanyPayeeBrokerName()
  const onChangeForCompanyPayeeBrokerName = React.useCallback((e) => {
    getCompanyPayeeBrokerNameChoices(e.target.value)
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4} container spacing={1}>
        <Grid item xs={12} md={6}>
          <TextField
            {...commonInputProps}
            label="物件名"
            name="name"
            rules={rules.name}
            disabled={props.disabled}
          />
        </Grid>

        <Grid item xs={6} md={2}>
          <TextField
            {...commonInputProps}
            label="棟数"
            name="businessbalance.buildings_count"
            rules={rules.businessbalance.buildings_count}
            type="number"
            disabled={props.disabled}
            color="yellow"
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <TextField
            {...commonInputProps}
            label="都道府県"
            name="businessbalance.prefecture_name"
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <SelectForm
            {...commonInputProps}
            label="情報先"
            name="companypayeebroker.name"
            rules={rules.companypayeebroker.name}
            disabled={props.disabled}
            setValue={setValue}
            freeSolo
            onChange={onChangeForCompanyPayeeBrokerName}
            choices={companyPayeeBrokerNameChoices}
            loading={companyPayeeBrokerNameIsLoading}
            onFocus={(name) => {
              commonInputProps.onFocus(name)
              getCompanyPayeeBrokerNameChoices(getValues(name))
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SelectForm
            {...commonInputProps}
            label="仲介種別"
            name="businessbalance.information_target_type"
            rules={rules.businessbalance.information_target_type}
            choices={choices?.information_target_types}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <SelectForm
            {...commonInputProps}
            label="権利 *"
            name="businessbalance.leasehold"
            rules={rules.businessbalance.leasehold}
            choices={choices?.leaseholds}
            disabled={props.disabled}
            setValue={setValue}
            color="yellow"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            {...commonInputProps}
            label="地代(坪単価)"
            name="businessbalance.rent_tsubo_unit_price"
            type="number"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <TextField
            {...commonInputProps}
            label="仕様 *"
            name="businessbalance.specification"
            rules={rules.businessbalance.specification}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectForm
            {...commonInputProps}
            label="エリア"
            name="businessbalance.specification_area"
            rules={rules.businessbalance.specification_area}
            choices={choices?.specification_area}
            disabled={props.disabled}
            setValue={setValue}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <SelectForm
            {...commonInputProps}
            label="行政区"
            name="businessbalance.property_city"
            rules={rules.businessbalance.property_city}
            choices={choices?.property_city}
            disabled={props.disabled}
            setValue={setValue}
            color="yellow"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            {...commonInputProps}
            label="町名 *"
            name="businessbalance.property_building_name"
            rules={rules.businessbalance.property_building_name}
            disabled={props.disabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            {...commonInputProps}
            label="番地 *"
            name="businessbalance.property_block"
            rules={rules.businessbalance.property_block}
            disabled={props.disabled}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} lg={8} container spacing={1} alignItems="flex-end">
        <Grid item xs={12} md={3}>
          <SelectForm
            {...commonInputProps}
            name="businessbalance.sales_method"
            rules={rules.businessbalance.sales_method}
            choices={choices?.sales_methods}
            disabled={props.disabled}
            setValue={setValue}
            color="yellow"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            {...commonInputProps}
            label="1棟粗利"
            name="businessbalance.gross_profit_per_building"
            type="number"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <TextField
            {...commonInputProps}
            label="1棟あたり(借地・立退・関係費)"
            name="businessbalance.leased_land_eiction_related_expenses_per_building"
            type="number"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            {...commonInputProps}
            label="事業利益率"
            name="businessbalance.business_profit_rate"
            type="float"
            disabled={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">%</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            {...commonInputProps}
            label="事業期間"
            name="businessbalance.business_period_month"
            type="float"
            disabled={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">ヵ月</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            {...commonInputProps}
            name="businessbalance.business_period_date"
            type="number"
            disabled={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">日</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextField
            {...commonInputProps}
            label="年率換算"
            name="businessbalance.annualized_rate_percent"
            disabled={true}
            type="float"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">%</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            {...commonInputProps}
            name="businessbalance.annualized_rate_per_year"
            disabled={true}
            type="float"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">回転/年</InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            {...commonInputProps}
            label="想定総売上"
            name="businessbalance.assumption_total_sales"
            type="number"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            {...commonInputProps}
            label="仕入値 *"
            name="businessbalance.cost_price"
            rules={rules.businessbalance.cost_price}
            type="number"
            disabled={props.disabled}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
            color="yellow"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            {...commonInputProps}
            label="総原価"
            name="businessbalance.total_cost"
            type="number"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...commonInputProps}
            label="坪単価"
            name="businessbalance.tsubo_unit_price"
            type="number"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <TextField
            {...commonInputProps}
            label="想定総粗利"
            name="businessbalance.assumption_total_gross_profit"
            type="number"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            {...commonInputProps}
            label="更地価格"
            name="businessbalance.raw_land_price"
            type="number"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <TextField
            {...commonInputProps}
            label="坪単価"
            name="businessbalance.tsubo_unit_price2"
            type="number"
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">￥</InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
})
BusinessBalanceForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
}

const BusinessBalanceTradeTargetLotNumberForm = React.memo(function businessBalanceTradeTargetLotNumberForm(props) {

  const { commonInputProps, rules, choices, handleRemoveForTradeTargetLotNumber, errors, tradeTargetLotNumberFields, isModal, setValue } = props

  const data = [
    {
      label: "番地",
      input: (index) => {
        return (
          <TextField
            {...commonInputProps}
            name={`businessbalance.businessbalancetradetargetlotnumber_set.${index}.block`}
            rules={
              rules.businessbalance
                .businessbalancetradetargetlotnumber_set.block
            }
            disabled={props.disabled}
          />
        )
      },
      grid: 3,
    },
    {
      label: "種類",
      input: (index) => {
        return (
          <SelectForm
            {...commonInputProps}
            name={`businessbalance.businessbalancetradetargetlotnumber_set.${index}.category`}
            rules={
              rules.businessbalance
                .businessbalancetradetargetlotnumber_set
                .category
            }
            choices={choices?.land_categories}
            disabled={props.disabled}
            setValue={setValue}
          />
        )
      },
      grid: 4,
    },
    {
      label: "面積",
      input: (index) => {
        return (
          <TextField
            {...commonInputProps}
            name={`businessbalance.businessbalancetradetargetlotnumber_set.${index}.area`}
            rules={
              rules.businessbalance
                .businessbalancetradetargetlotnumber_set.area
            }
            type="float"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  ㎡
                </InputAdornment>
              ),
            }}
            disabled={props.disabled}
          />
        )
      },
      grid: 3,
    },
  ]
  return (
    <FormControl
      error={
        errors.businessbalance
          ?.businessbalancetradetargetlotnumber_set
          ? true
          : false
      }
    >
      <Grid container direction="row" spacing={1} alignItems="center">
        {tradeTargetLotNumberFields.map((item, index) => (
          <React.Fragment key={index}>
            {!isModal
              ?
              <>
                {index === 0 && (
                  <>
                    {data.map((item, dataIndex) => {
                      return (
                        <Grid key={dataIndex} item xs={item.grid}>
                          <Typography>
                            {item.label}
                          </Typography>
                        </Grid>
                      )
                    })}
                    <Grid item xs={12} md={1} />
                  </>
                )}
                <HiddenInput
                  {...commonInputProps}
                  name={`businessbalance.businessbalancetradetargetlotnumber_set.${index}.id`}
                />
                {data.map((item, dataIndex) => {
                  return (
                    <Grid key={dataIndex} item xs={item.grid}>
                      {item.input(index)}
                    </Grid>
                  )
                })}
                <Grid item xs={2}>
                  <DeleteButton
                    onClick={() =>
                      handleRemoveForTradeTargetLotNumber(index)
                    }
                    disabled={props.disabled}
                  />
                </Grid>
              </>
              :
              <>
                <HiddenInput
                  {...commonInputProps}
                  name={`businessbalance.businessbalancetradetargetlotnumber_set.${index}.id`}
                />
                <Grid item xs={10}>
                  <Grid container direction="row" spacing={1}>
                    {data.map((item, dataIndex) => {
                      return (
                        <React.Fragment key={dataIndex} >
                          <Grid item xs={2}>
                            <Typography>
                              {item.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            {item.input(index)}
                          </Grid>
                        </React.Fragment>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <DeleteButton
                    onClick={() =>
                      handleRemoveForTradeTargetLotNumber(index)
                    }
                    disabled={props.disabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            }
          </React.Fragment>
        ))}
      </Grid>
    </FormControl>
  )
})
BusinessBalanceTradeTargetLotNumberForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  handleRemoveForTradeTargetLotNumber: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  tradeTargetLotNumberFields: PropTypes.array.isRequired,
  isModal: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
}

const BusinessBalanceTradeTargetLotNumberTotalForm = React.memo(function businessBalanceTradeTargetLotNumberTotalForm(props) {

  const { control } = props
  return (

    <Stack direction="row" alignItems="center" spacing={1} sx={{ mx: 1, whiteSpace: "nowrap" }}>
      <Typography variant="subtitle1">合計：</Typography>
      <TextField
        control={control}
        name="businessbalance.total_brush"
        type="number"
        disabled={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">筆</InputAdornment>
          ),
        }}
      />
      <TextField
        control={control}
        name="businessbalance.total_area"
        type="float"
        disabled={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">㎡</InputAdornment>
          ),
        }}
      />
    </Stack>
  )
})

BusinessBalanceTradeTargetLotNumberTotalForm.propTypes = {
  control: PropTypes.object.isRequired,
}

const BusinessBalancePrivateRoadForm = React.memo(function businessBalancePrivateRoadForm(props) {
  const { commonInputProps, rules, choices, handleRemoveForPrivateRoad, errors, privateRoadFields, isModal, setValue } = props

  const data = [
    {
      label: "番地",
      input: (index) => {
        return (
          <TextField
            {...commonInputProps}
            name={`businessbalance.businessbalanceprivateroad_set.${index}.block`}
            rules={
              rules.businessbalance
                .businessbalanceprivateroad_set.block
            }
            disabled={props.disabled}
          />
        )
      },
      grid: 2,
    },
    {
      label: "種類",
      input: (index) => {
        return (
          <SelectForm
            {...commonInputProps}
            name={`businessbalance.businessbalanceprivateroad_set.${index}.category`}
            rules={
              rules.businessbalance
                .businessbalanceprivateroad_set.category
            }
            choices={choices?.land_categories}
            disabled={props.disabled}
            setValue={setValue}
          />
        )
      },
      grid: 3,
    },
    {
      label: "面積",
      input: (index) => {
        return (
          <TextField
            {...commonInputProps}
            name={`businessbalance.businessbalanceprivateroad_set.${index}.area`}
            rules={
              rules.businessbalance
                .businessbalanceprivateroad_set.area
            }
            type="float"
            disabled={props.disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  ㎡
                </InputAdornment>
              ),
            }}
          />
        )
      },
      grid: 3,
    },
    {
      label: "持分",
      input: (index) => {
        return (
          <TextField
            {...commonInputProps}
            name={`businessbalance.businessbalanceprivateroad_set.${index}.equity`}
            rules={
              rules.businessbalance
                .businessbalanceprivateroad_set.equity
            }
            type="text"
            disabled={props.disabled}
          />
        )
      },
      grid: 2,
    },
  ]

  return (
    <FormControl
      error={
        errors.businessbalance?.businessbalanceprivateroad_set
          ? true
          : false
      }
    >
      <Grid
        container
        spacing={1}
        columns={11}
        alignItems="center"
      >

        {privateRoadFields.map((item, index) => (
          <React.Fragment key={index}>
            {!isModal
              ?
              <>
                {index === 0 && (
                  <>
                    {data.map((item, dataIndex) => {
                      return (
                        <Grid key={dataIndex} item xs={11} md={item.grid}>
                          <Typography>
                            {item.label}
                          </Typography>
                        </Grid>
                      )
                    })}
                    <Grid item xs={12} md={1} />
                  </>
                )}
                <HiddenInput
                  {...commonInputProps}
                  name={`businessbalance.businessbalanceprivateroad_set.${index}.id`}
                />
                {data.map((item, dataIndex) => {
                  return (
                    <Grid key={dataIndex} item xs={11} md={item.grid}>
                      {item.input(index)}
                    </Grid>
                  )
                })}
                <Grid item xs={12} md={1}>
                  <DeleteButton
                    onClick={() => handleRemoveForPrivateRoad(index)}
                    disabled={props.disabled}
                  />
                </Grid>
              </>
              :
              <>
                <HiddenInput
                  {...commonInputProps}
                  name={`businessbalance.businessbalanceprivateroad_set.${index}.id`}
                />
                <Grid item xs={9}>
                  <Grid container direction="row" spacing={1}>
                    {data.map((item, dataIndex) => {
                      return (
                        <React.Fragment key={dataIndex} >
                          <Grid item xs={2}>
                            <Typography>
                              {item.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            {item.input(index)}
                          </Grid>
                        </React.Fragment>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <DeleteButton
                    onClick={() => handleRemoveForPrivateRoad(index)}
                    disabled={props.disabled}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Divider />
                </Grid>
              </>
            }
          </React.Fragment>
        ))}
      </Grid>
    </FormControl>
  )

})
BusinessBalancePrivateRoadForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  handleRemoveForPrivateRoad: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  privateRoadFields: PropTypes.array.isRequired,
  isModal: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
}

const BusinessBalanceRoadsideLandPricesRatioForm = React.memo(function businessBalanceRoadsideLandPricesRatioForm(props) {
  const { commonInputProps, rules, errors, roadsideLandPricesRatioFields, isModal } = props

  const columns = isModal ? 12 : 13
  return (
    <FormControl
      error={
        errors.businessbalance
          ?.businessbalanceroadsidelandpricesratio_set
          ? true
          : false
      }
    >
      <Stack direction="column" spacing={1}>
        {roadsideLandPricesRatioFields.map((item, index) => (
          <Box key={index}>
            <Grid
              container
              spacing={1}
              columns={columns}
            >
              <HiddenInput
                {...commonInputProps}
                name={`businessbalance.businessbalanceroadsidelandpricesratio_set.${index}.id`}
              />
              <HiddenInput
                {...commonInputProps}
                name={`businessbalance.businessbalanceroadsidelandpricesratio_set.${index}.year`}
              />
              <Grid item xs={columns} md={isModal ? columns : 2}>
                <Typography>{item.displayYear}度</Typography>
              </Grid>
              <Grid item xs={columns} md={isModal ? columns : 4}>
                <TextField
                  {...commonInputProps}
                  name={`businessbalance.businessbalanceroadsidelandpricesratio_set.${index}.price`}
                  rules={
                    rules.businessbalance
                      .businessbalanceroadsidelandpricesratio_set
                      .price
                  }
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        千円/㎡
                      </InputAdornment>
                    ),
                  }}
                  disabled={props.disabled}
                  color="yellow"
                />
              </Grid>
              <Grid item xs={isModal ? 4 : columns} md={isModal ? 4 : 3}>
                <TextField
                  {...commonInputProps}
                  name={`businessbalance.businessbalanceroadsidelandpricesratio_set.${index}.roadside_land_unit_price`}
                  disabled={true}
                  type="float"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        万円/坪
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={isModal ? 4 : columns} md={isModal ? 4 : 2}>
                <TextField
                  {...commonInputProps}
                  name={`businessbalance.businessbalanceroadsidelandpricesratio_set.${index}.roadside_land_ratio`}
                  disabled={true}
                  type="float"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        %
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={isModal ? 4 : columns} md={isModal ? 4 : 2}>
                <TextField
                  {...commonInputProps}
                  name={`businessbalance.businessbalanceroadsidelandpricesratio_set.${index}.roadside_land_ratio2`}
                  disabled={true}
                  type="float"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        %
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
      </Stack>
    </FormControl>
  )
})
BusinessBalanceRoadsideLandPricesRatioForm.propTypes = {
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  roadsideLandPricesRatioFields: PropTypes.array.isRequired,
  isModal: PropTypes.bool.isRequired,
}

const BusinessBalanceOtherForm = React.memo(function businessBalanceOtherForm(props) {
  const { commonInputProps, businessbalance, rules, choices, errors, specialFactorFields, setValue } = props

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={6}>
        <TextField
          {...commonInputProps}
          label="情報取得者"
          name="businessbalance.information_acquisition_person"
          rules={rules.businessbalance.information_acquisition_person}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          {...commonInputProps}
          label="業務担当者"
          name="businessbalance.business_representative"
          rules={rules.businessbalance.business_representative}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={8}>
        <SelectForm
          {...commonInputProps}
          label="販売チャンネル"
          name="businessbalance.sales_channel"
          rules={rules.businessbalance.sales_channel}
          choices={choices.sales_channel}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          {...commonInputProps}
          label={businessbalance.sales_channel_target_label}
          name="businessbalance.sales_channel_target"
          rules={rules.businessbalance.sales_channel_target}
          disabled={props.disabled}
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{businessbalance.sales_channel_target_unit}</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectForm
          {...commonInputProps}
          label="ハザード"
          name="businessbalance.hazard"
          rules={rules.businessbalance.hazard}
          choices={choices.applicable}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          {...commonInputProps}
          name="businessbalance.hazard_text"
          rules={rules.businessbalance.hazard_text}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectForm
          {...commonInputProps}
          label="浸水履歴"
          name="businessbalance.flooding_history"
          rules={rules.businessbalance.flooding_history}
          choices={choices.flooding_history}
          disabled={props.disabled}
          setValue={setValue}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          {...commonInputProps}
          name="businessbalance.flooding_history_text"
          rules={rules.businessbalance.flooding_history_text}
          disabled={props.disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl
          error={
            errors.businessbalance?.businessbalancespecialfactor_set
              ? true
              : false
          }
        >
          <FormLabel>特殊要因</FormLabel>

          <Stack direction="column" spacing={1}>
            {specialFactorFields.map((item, index) => (
              <React.Fragment key={index}>
                <HiddenInput
                  {...commonInputProps}
                  name={`businessbalance.businessbalancespecialfactor_set.${index}.id`}
                />
                <SelectForm
                  {...commonInputProps}
                  name={`businessbalance.businessbalancespecialfactor_set.${index}.special_factor`}
                  rules={
                    rules.businessbalance
                      .businessbalancespecialfactor_set.special_factor
                  }
                  choices={choices?.special_factors}
                  disabled={props.disabled}
                  setValue={setValue}
                />
              </React.Fragment>
            ))}
          </Stack>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <DatePicker
          {...commonInputProps}
          label="測量UP期日"
          name="businessbalance.measurement_up_deadline"
          disabled={true}
          defaultValue={null}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label="契約-測量UP"
          name="businessbalance.from_contract_up_to_measurement"
          type="number"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">日間</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...commonInputProps}
          label="解体想定期間"
          name="businessbalance.dismantling_assumption_period"
          type="number"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">日間</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          {...commonInputProps}
          label="発表予定日"
          name="businessbalance.announcement_expected_date"
          disabled={true}
          defaultValue={null}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          {...commonInputProps}
          label="土地引渡日"
          name="businessbalance.land_passing_date"
          disabled={true}
          defaultValue={null}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          {...commonInputProps}
          label="建物引渡日"
          name="businessbalance.building_passing_date"
          disabled={true}
          defaultValue={null}
        />
      </Grid>
    </Grid>
  )
})
BusinessBalanceOtherForm.propTypes = {
  businessbalance: PropTypes.object.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  choices: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  specialFactorFields: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
}